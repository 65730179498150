.legal-page {
    margin: 30px 0;
    
    .terms-and-conditions {
        color: #ffffff;
        
        .main-content {
            padding-top: 20px;
            @media(min-width: 992px) {
                padding-left: 40px;
            }
        
            h1 {
                padding-bottom: 30px;
                border-bottom: 1px solid #ffffff;
                margin-bottom: 33px;
            }

            ul{
                font-weight: 300;
                li{
                    margin-bottom: 5px;
                }
            }

        }
    }
}
