.single-post {
    display: flex;
    flex-direction: column;
    margin: 20px;
    color: #ffffff;
    
    @media(min-width: 992px) {
        margin: 50px;
    }

    h1 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 30px;

        @media(min-width:768px){
            font-size: 40px;
            line-height: 43px;
        }
    }
    .blog-date {
        margin-top: 10px;
    }
    .post-image-box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 30px 0px;
        .post-image {
            width: 100%;
            background-position: center;
            background-size: cover;
            border-radius: 10px;
            @media (min-width: 0px) and (max-width: 767px){
                height: 270px;
            }
            @media (min-width: 768px){
                height: 400px;
            }
            &:hover{
                transform: scale(1);
            }
        }
    }
    .blog-content-box {
        p {
            font-size: 1.25rem;
            line-height: 1.5;
            strong{
                color: var(--orange)
            }
        }
    }

    img{
        max-width: 100%;
        height: auto;
    }

    .featured-media{
        margin: 2rem 0;
    }

}