.form-input,
.form-row{
    position: relative;

    label{
        display: block;
    }

    .form-input-wrap {
        display: flex;
    }

    textarea,
    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=password]{
        font-size: 16px;
        padding: 14.5px 15px;
        border-radius: 5px;
        border: 1px solid #ffffff;
        background-color: #131313;
        color: #ffffff;
        position: relative;
        width: 100%;
        outline: 0px solid #099ed4;
        transition: all .2s ease-out;
        margin: 0;
        &:focus{
            box-shadow: 0 0 0 3px #099ed4;
        }
        &.required-input {
            border: 1px solid #ff9e9e;
        }
        &.success-input {
            border: 1px solid rgb(90, 152, 90);
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0 0 0px 1000px rgba(36, 44, 30, 0.81) inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .required-field-message {
        color: #ff9e9e;
        text-align: right;
        position: absolute;
        right: 0;
        font-size: 10px;
        bottom: -15px;
    }

    .flags-select{
        button{
            span{
                color: white !important;
            }
        }
    }

}
