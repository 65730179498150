.radio-wrap {
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	margin-bottom: 0px;
	margin-top: 25px;
	& > label{
		margin-right: 20px;
	}
	.radio-gender-wrap{
		display: flex;
		align-items: center;
		.form-radio-input{
			padding-left: 35px;
			margin-right: 15px;
		}
	}
}

/* Customize the label (the from-radio-input) */
.form-radio-input {
    display: block;
    position: relative;
    padding-left: 45px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    label {
      	cursor: pointer;
    }

    .label {
		font-size: 16px;
		line-height: 29px;
    }

    /* Hide the browser's default radio button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
  
    /* Create a custom radio button */
    .checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 29px;
		width: 29px;
		border-radius: 50%;
		border: 1px solid #ffffff;
		background-color: transparent;
		cursor: pointer;
    }
}
  
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
  
/* Show the indicator (dot/circle) when checked */
.form-radio-input input:checked ~ .checkmark:after {
	display: block;
}
  
.form-radio-input input:focus ~ .checkmark{
	outline: 2px solid var(--default);
}

/* Style the indicator (dot/circle) */
.form-radio-input .checkmark:after {
    top: 7px;
    left: 7px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ffffff;
}

.radio-wrap.required-input{
	.form-radio-input{
		color: #ff9e9e;
		.checkmark{
			border-color: #ff9e9e;
		}
	}
}