.select-indentifier {
    border-radius: 5px;
    border: 1px solid #ffffff;
    background-color: #131313;
    color: #ffffff;
    position: relative;
    z-index: 999;
    div:first-of-type {
        background-color: #131313;
        border: none;
        color: #ffffff!important;
        display: flex;
        align-items: center;
        border-radius: 5px;
    }
}


.select-input__single-value,
.select-input__control,
.select-input__control--is-focused,
.select-input__control--menu-is-open{
    background-color: black !important;
    .select-input__input-container,
    .select-input__single-value{
        color: white !important;
    }

    .select-input__value-container{
        padding: 10.5px 15px !important;
    }

    .select-input__input{
        height: auto !important;
    }
}

.select-input__control--is-focused{
    box-shadow: 0px 0px 0px 3px var(--default) !important;
    input:focus{
        box-shadow: none !important;
    }
}

.select-input__menu-portal{

    .select-input__menu,
    .select-input__menu-list{
        margin: 0 !important;
        background: none !important;
    }

    .select-input__menu{
        margin-top: 1px !important;
        background: black !important;
        border-bottom: 1px solid white;
        border-left: 1px solid white;
        border-right: 1px solid white;
    }


    .select-input__option{
        background: none !important;
        color: white !important;
        &.select-input__option--is-focused{
            background: #393939 !important;
        }
        &.select-input__option--is-selected{
            background: var(--default) !important;
        }
    }

}

.select-input__menu-portal{
    z-index: 9999999999 !important;
}