.login-container{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 99999;
    justify-content: center;
    align-items: center;
    background: #000000cc;
}

.login-popup {
    display: flex;
    width: 95%;
    max-width: 930px;
    z-index: 999;
    height: 630px;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    background: black;
    border: 1px solid var(--live-casino-hover);
    box-shadow: 0px 15px 25px black;
    @media only screen and (max-width: 768px) {
        width: 100%;
        height: 100vh;
    }
    .login-wrapper{
        width: 50%;
        height: 100%;
        padding: 20px 55px;
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        @media only screen and (max-width: 768px) {
            width: 100%;
            padding: 20px 30px;
        }
        .forgot-password {
            text-decoration: underline;
        }
        form {
            .form-submit {
                margin-top: 1rem;
                margin-bottom: 1rem;
                button {
                    width: 100%;
                    font-size: 18px;
                    line-height: 36px;
                    height: 52px;
                }
            }
        }
        .form-input {
            margin-bottom: 20px;
            input {
                height: 50px;
                border-radius: 5px;
                margin-top: 0px;
                color: #ffffff;
                &:focus-visible {
                    outline: none;
                }
            }
            label{
                padding-bottom: 10px;
                font-size: 16px;
            }
        }
        input{
            width: 100%;
        }
        width: 587px;
        h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-align: left;
            margin-bottom: 0px;
        }
    } 
    .login-image {
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: 50%;
        @media only screen and (max-width: 768px) {
           display: none;
        }
    }  
    .signup {
        margin-top: 0;
        color: #ffffff;
        span {
            cursor: pointer;
            color: var(--live-casino);
            text-decoration: underline;
        }
    }
    .login-exit {
        position: absolute;
        right: 26px;
        font-size: 18px;
        margin: 0px;
        cursor: pointer;
        @media (min-width: 0px) and (max-width: 767px){
            top: 70px;
        }
        @media (min-width: 768px){
            top: 38px;
        }
    }
}

.forgot-password-container {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .form-input {
        label {
            font-size: 18px;
            color: #ffffff;
            margin-bottom: 14px;
        }
    }
    h3 {
        font-size: 24px;
        margin: 0px;
        margin-bottom: 72px;
        color: #ffffff;
        width: 432px;
    }
    input {
        height: 50px;
        border-radius: 5px;
        border: 1px solid #ffffff;
        background-color: #131313;
        color: #ffffff;
        margin-bottom: 50px;
        width: 432px;
    }
}

.password-wrap{
    position: relative;
    .btn-pwd-toggle{
        position: absolute;
        top: 0;
        bottom: -30px;
        right: 15px;
        cursor: pointer;
        margin: auto;
        height: 20px;
    }
}