.link-container-main {
    padding-bottom: 0;

    @media (min-width: 0px) and (max-width: 767px){
        padding-top: 0;
    }
    @media (min-width: 768px){
        padding: 50px 0px;
    }

    .footer-links-box {

        .mobile {
            display: block;

            @media(min-width: 992px) {
                display: none
            }
        }

        .desktop {
            display: none;

            @media(min-width: 992px) {
                display: block;
            }
        }
    }
    
    @media(min-width: 992px) {
        padding: 50px 0px;
    }

    .links-container {
        padding: 25px 0px;
        margin: 0 auto;
        
        .container {
            // margin: 0;
            // width: 100%;
            
            // @media(min-width: 992px) {
            //     padding-left: 60px;
            // }

            h2 {
                font-size: 20px;
                @media (min-width: 0px) and (max-width: 767px){
                    text-align: center;   
                }
            }

            .accordion-wrap {
    
                .accordion-item {
                    @media (min-width: 0px) and (max-width: 767px){
                        margin-bottom: 10px;
                    }
                    @media (min-width: 768px){
                        margin-bottom: 20px;
                    }
                    .accordion-title {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        cursor: pointer;
                        background-color: #000000;
                        border-radius: 10px;
                        color: #ffffff;

                        .shevron-up {
                            
                            img {
                                transform: rotate(180deg);
                            }
                        }
                    }
            
                    .accordion-title,
                    .accordion-content {
                        padding: 20px 35px;
                    }
    
                    .accordion-content {
                        padding-bottom: 0;

                        li {
                            list-style: none;
                            margin-bottom: 15px;
                        }
                    }
                }
            }

            .games {

            }

            .terms {
                
            }

            .social {
                @media (min-width: 0px) and (max-width: 767px){
                    margin-top: 25px;   
                }
                ul {
                    @media (min-width: 768px){
                        max-width: 200px;
                    }
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
        .linksbox {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .link {
                padding: 10px 15px;
                a {
                    color: white;
                    text-decoration: none;
                    font-size: 15px;
                }
            }
        }

        .footer-box{
            margin-top: 15px;
            @media (min-width: 0px) and (max-width: 767px){
                text-align: center;   
            }
        }

        .license-box{
            margin-top: 15px;
            margin-bottom: 35px;
            border-radius: 15px;
            background-color: #e1e1e1;
            display: inline-block;
            padding: 10px;
            iframe{
                float: left;
            }
        }
    
        
    }
}
