.my-account-page {
    color: #ffffff;
    padding-bottom: 100px;

    &.filter-opened {     
        &::before {
            position: absolute;
            display: block;
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 9;
            
        }
    }

    .top-bar{
        padding: 0px 8px;
        border-bottom: 1px solid white;
        @media (min-width: 0px) and (max-width: 767px){
            margin-bottom: 0;
        }
        @media (min-width: 768px){
            margin-bottom: 35px;   
        }
    }

    .balance{
        display: flex;
        margin-bottom: 10px;
        .user-balance-box{
            margin-left: 6px;
        }
    }

    .balance-wrap{
        padding-left: 25px;
        text-align: center;
        img{
            width: 120px;
            height: auto;
        }
    }

    .steps-form {
        width: 100%;
        padding: 8px;
        @media (min-width: 0px) and (max-width: 767px){
            padding-top: 25px;   
        }
        label { display: none; }
    }

    .notes {
        padding: 25px 30px;
        background: #444331;
        margin-top: 35px;
        border-radius: 15px;
    }

    .buttons-wrap{
        display: flex;
    }

    .navbar {
        background-color: #131313;
        border-bottom: 1px solid black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
        @media (min-width: 0px) and (max-width: 767px){
            margin-bottom: -85px;
            margin-top: 15px;
        }
        @media(min-width: 992px) {
            flex-direction: row;
            padding: 12px 35px;
        }
        
        .main-title {
            font-size: 20px;
            font-weight: bold;
            margin-top: 20px;

            @media (min-width: 0px) and (max-width: 767px){
                display: none;
            }
            
            @media(min-width: 768px) {
                margin-top: 0px;
                font-size: 24px;
            }
        }
        
        .balance-wrap {
            width: 100%;
            display: flex;
            font-size: 18px;
            flex-direction: column;

            @media(min-width: 992px) {
                flex-direction: row;
                justify-content: flex-end;
            }

            .amount {
                font-weight: bold;
                margin-left: 5px;
                flex: 1 0 auto;
            }
            
            .main-balance {
                margin-right: 36px;
                display: flex;
                margin-bottom: 15px;
                
                @media (min-width: 0px) and (max-width: 991px){
                    margin-top: 15px;
                }
                @media(min-width: 992px) {
                    margin-bottom: 0px;
                }
            }
            
            .bonus-balance {
                display: flex;
                margin-bottom: 20px;

                @media(min-width: 992px) {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .mobile-sidebar{
        margin-top: 85px;
    }

    .sidebar {
        margin-top: 25px;
        background-color: #000000;
        padding: 0;
        display: none;
        position: sticky;
        top: 120px;
        @media(min-width: 992px) {
            display: block;
        }

        ul {
            margin: 0;
            padding: 0;
            li {
                position: relative;
                list-style: none;
                background-color: transparent;
                transition: .3s all ease-in-out;
                border-left: 4px solid #243042;
                cursor: pointer;
                &:hover {
                    background-color: #131313;
                }
                
                span {
                    padding: 15px 25px;
                    text-decoration: none;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    
                    img {
                        margin-right: 20px;
                        width: 22px;
                    }
                }

                .active-line {
                    width: 5px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: var(--default);
                }
                
            }
        }
    }

    .sidebar-mobile {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 25px;
        border-bottom: 1px solid #ffffff40;

        @media(min-width: 992px) {
            display: none;
        }

        .sidebar-title {
            height: 50px;
        }

        .current-item {
            display: flex;
            width: 100%;
            justify-content: space-between;

            img {
                width: 14px;
            }
        }
        
        .dropdown {
            display: none;
            position: absolute;
            width: 100%;
            top: 50px;
            left: 0;
            padding: 20px;
            background-color: #282828;
            border-radius: 10px;
            z-index: 9000;

            a {
                margin-bottom: 15px;
                color: white;
                text-decoration: none;
                display: flex;
                align-items: center;

                img {
                    margin-right: 20px;
                    width: 18px;
                }
            }
        }

        .dropdown.open {
            display: flex;
            flex-direction: column;
        }
    }
    
    .main-content {
        padding-top: 20px;
        
        @media(min-width: 992px) {
            padding-left: 40px;
        }

        h3 {
            font-size: 18px;
            padding-bottom: 40px;
            border-bottom: 1px solid #ffffff;
            margin-bottom: 33px;
            display: none;

            @media(min-width: 992px) {
                display: block;
            }
        }

        .rdt_Table {
    
            .rdt_TableHeadRow {
                font-weight: bold;
                background-color: black;
                color: #ffffff;
                font-weight: bold;
                font-size: 14px;
                min-height: 52px;
            }
    
            .rdt_TableRow {
                background-color: #1b1b1b;
                border: none;
                color: #ffffff;
                font-size: 14px;
                min-height: 52px;
                
                &:nth-of-type(even) {
                    background-color: #2c2c2c;
                }
            }

        }

        .rdt_Pagination {
            color: #ffffff;
            background-color: #243042;
            border-top: 1px solid #ffffff;

            select {

                option {
                    background-color: #243042;
                    color: #ffffff;
                }
            }

            button {

                svg {

                    path:first-child {
                        fill: #ffffff;
                    }
                }
            }
        }
    }

    .setting-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .content {

            .title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 12px;
            }

            .text {
                font-size: 14px;
            }
        }
    }

    .input-text {
        border: 1px solid #ffffff;
        background-color: #131313;
        border-radius: 5px;
        color: white;

        &::placeholder {
            color: white;
        }
    }
}