.message-output{
    margin: 15px 0px;
    position: relative;
    z-index: 5;
    div{
        color: black;
        padding: 8px 8px;
        text-align: center;
        border-radius: 15px;
    }
}