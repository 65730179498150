.form-input,
.form-row{
    position: relative;
    &.form-phone-row{
        z-index: 9999;
        .required-field-message{
            bottom: 55px;
        }
    }
    .form-input-wrap{
        .react-tel-input input.form-control{
            height: 49.5px;
            padding-left: 70px;
        }
        .react-tel-input .flag-dropdown{
            background: black;
        }
        .react-tel-input .selected-flag{
            width: 54px;
            padding: 0 0 0 16px;
        }
        .react-tel-input .flag-dropdown.open .selected-flag,
        .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
            background: #333;
        }
        .react-tel-input .country-list{
            margin-top: 0;
        }
        .react-tel-input .country-list .search{
            padding: 0.25rem;
        }
        .react-tel-input .country-list .search-box{
            width: 100%;
            margin: 0;
            border-radius: 0;
        }
        .react-tel-input .country-list .search-emoji {
            font-size: 15px;
            position: absolute;
            top: 6px;
            right: 0.5rem;
        }

        .react-tel-input .country-list .country{
            padding: 0.25rem 0.825rem;
            color: black;
            font-size: 1rem;
        }

        .react-tel-input .selected-flag .arrow{
            transition: all .2s ease-out;
        }

        .react-tel-input .selected-flag .flag[class*="0"] .arrow{
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid #fff;
            left: 6px;
        }

        .react-tel-input.required-input{
            input.form-control, .flag-dropdown{
                border-color: #ff9e9e;
            }
        }

    }
}
