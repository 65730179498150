.thankyou-page {   
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    z-index: 99999;
    background: white;
    background-size: cover;
    background-position: center;

    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000cf;
        z-index: 1;
    }

    .content{
        position: relative;
        z-index: 5;
        text-align: center;
        background: #0000009e;
        padding: 4rem;
        border-radius: 1rem;
        flex-direction: column;
        @media (min-width: 768px){
            min-width: 600px;
        }
        img{
            margin: auto;
            max-width: 100px;
            margin-bottom: 2rem;
        }
    }
    
    .thankyou-title {
        font-size: 3rem;
        margin: 0px 0px 1rem 0px;
        line-height: 3.25rem;
        font-weight: 700;
    }
    .thankyou-text {
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: white;
        font-weight: 300;
        margin: 0px;
        margin-bottom: 2rem;
    }
    .thankyou-trouble {
        font-size: 18px;
        color: white;
        line-height: 27px;
        font-weight: 400;
        margin: 0px;
        margin-bottom: 62px;
        a {
            color: #0094FF;
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 0px 30px;
        text-align: center;
        img {
            width: 90px;
        }
        .thankyou-title {
            font-size: 36px;
            margin: 20px 0px;
        }
        .thankyou-text {
            font-size: 18px;
            line-height: 26px;
            margin: 0px;
            margin-bottom: 25px;
        }
        .thankyou-trouble {
            font-size: 16px;
            margin-bottom: 40px;
        }
    }
}