.footer-links-box.social {

    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 0px) and (max-width: 767px){
            justify-content: center;
        }
        
        @media(min-width: 768px) {
            justify-content: flex-start;
        }
        
        li {
            
            @media (min-width: 0px) and (max-width: 767px){
                margin-left: 10px;
                margin-right: 10px;
            }
            @media(min-width: 768px) {
                margin-right: 30px;
            }

            img {
                width: 30px;
                opacity: 0.6;
            }
            a:hover{
                img{
                    opacity: 1;
                }
            }
        }
    }
}