.our-team-section {
    .our-team-title-area {
        padding: 2rem 0;
        padding-bottom: 0;
        h3 {
            color: #ffffff;
            font-size: 25px;
        }
    }
    .background-image-gif {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        opacity: 0;
        transition: 0.3s ease all;
    }
    .our-team-title {
        margin: 0px;
    }
    .our-team-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
        .member-name {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 0px;
            @media (max-width: 992px){
                font-size: 20px;
            }
        }
        .member-position {
            color: #F79533;
            font-size: 0.8rem;
            font-weight: 400;
            margin: 0px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    .our-team-image {
        width: 100%;
        max-width: 300px;
        height: 370px;
        @media (max-width: 1350px){
            max-width: 220px;
            height: 270px;
        }
        border: 1px solid #F79533;
        border-radius: 20px;
        background-position: center;
        background-size: cover;
        overflow: hidden;
        box-shadow: 0px 15px 25px black;
        &:hover {
            .background-image-gif {
                opacity: 1;
            }
        }
    }
    .our-team-box-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 992px){
            text-align: center;
        }
    }
}