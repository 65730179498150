.blog-title-area {
    background-color: #0c0c0c;
    padding: 2rem 0;
    h3 {
        margin: 0px;
        color: #ffffff;
        font-size: 25px;
    }
}

h1.blog-title{
    padding-left: 10px;
    margin-bottom: 3rem;
}

.blog-post-section {
    padding-top: 1rem;
    margin-bottom: 5rem;
    .post-box {
        .title-link {
            display: block;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    @media only screen and (min-width: 1650px)  {
        .container {
            max-width: 1600px;
        }
    }
}

.single-blog-page {
    .related-posts {
        width: 100%;
        .title-link {
            display: block;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .btn {
        margin-top: 30px;
        font-size: 18px;
    }
    .single-post-container {
        display: flex;
        justify-content: center;
        .single-post {
            max-width: 900px;
            width: 100%;
        }
    }
}

.blog-intro{
    padding: 0 1.5rem;
}

.post-body {
    min-height: 510px;
    border-radius: 10px;
    background: #131313;
    border: 1px solid #333;
    border-bottom: 5px solid var(--orange);
    margin-bottom: 1rem;
    &.no-image{
        min-height: 310px;
    }
    .link.btn{
        text-decoration: none;
        float: right;
        margin-top: 1.5rem;
        padding-left: 10px;
        border-left: 2px solid #f79533;
        border-radius: 2px;
    }

    .title-link{
        text-decoration: none;
    }

}

.post-image-box {
    overflow: hidden;
    border-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.post-image {
    min-height: 210px;
    transition: 0.3s ease all;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    &:hover {
        transform: scale(1.05);
    }
}

.post-content {
    margin-top: 0.5rem;
    min-height: 100px;
    p{
        font-size: 0.875rem;
        line-height: 1.5;
        margin: 0;
        color: #9b9b9b;
    }
}

h2 {
    color: #ffffff;
    margin: 0px;
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.blog-date {
    color: #9b9b9b;
    margin: 0px;
    font-size: 14px;
    margin-top: 1rem;
}

.blog-content-container {
    height: 200px;
    overflow: hidden;
}
