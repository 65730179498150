/* Customize the label (the form-checkbox-input) */
.form-checkbox-input {
	display: flex;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 100%;

	@media(min-width: 576px) {
		width: auto;
	}

	label {
		cursor: pointer;
		padding-left: 45px;
	}

	.label {
		font-size: 16px;
		line-height: 29px;

		@media(min-width: 576px) {
			min-width: 165px
		}

		@media(min-width: 576px) {
			margin-right: 50px;
		}
	}

	&.required-field{
		.label{
			color: #ff9e9e;
		}
		span.checkmark {
			border: 1px solid #ff9e9e;
		}
	}

}

/* Hide the browser's default checkbox */
.form-checkbox-input input {
	position: absolute;
	opacity: 0;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 29px;
	width: 29px;
	background-color: transparent;
	border: 1px solid #ffffff;
	border-radius: 5px;
	cursor: pointer;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.form-checkbox-input input:checked~.checkmark:after {
	display: block;
}

.form-checkbox-input input:focus~.checkmark {
	outline: 2px solid var(--default);
}

/* Style the checkmark/indicator */
.form-checkbox-input .checkmark:after {
	left: 7px;
	top: 7px;
	width: 13px;
	height: 13px;
	background-color: #ffffff;
	border-radius: 2px;
}