.hero-banner-slider-wrap {
    position: relative;
    transition: all .4s ease-out;
    opacity: 0;
    @media (min-width: 0px) and (max-width: 767px){
        padding-top: 83%;
    }
    @media (min-width: 768px){
        padding-top: 34%;
    }
    &.loaded{
        opacity: 1;
    }
}

.hero-slider {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 10px !important;
    @media (min-width: 0px) and (max-width: 1199px){
        padding: 10px 0px !important;
        padding-bottom: 25px !important;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    @media (min-width: 1200px){
        padding: 30px !important;
        padding-top: 5px !important; 
        margin-top: 15px;
    }

    .swiper-slide{
        @media (min-width: 0px) and (max-width: 767px){
            padding-top: 75%;
        }
        @media (min-width: 768px){
            padding-top: 32%;
        }
    }

    /* .swiper-slide{
        &.hasLink{
            cursor: pointer;
        }
        @media (min-width: 0px) and (max-width: 767px){
            padding-top: 69%;
        }
        @media (min-width: 768px){
            padding-top: 32%;
        }
        opacity: 0.7;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.224);
        transform: translateZ(0) scale(0.98) !important;
        transition: all .2s ease-out;
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 15px;
            top: 0;
            left: 0;
            border: 2px solid rgba(255, 255, 255, 0);
            transition: all .2s ease-out;
        }
        
        @media (min-width: 1024px){
            &:hover{
                &:after{
                    border: 2px solid rgba(255, 255, 255, 0.657);
                }
            }
        }
        &.swiper-slide-active{
            opacity: 1;
            transform: translateZ(0) scale(1) !important;
            box-shadow: 0px 25px 15px -15px black;
        }
    } */
    
    .swiper-button-next {
        transform: rotate(180deg);
    }

    .hero-banner-slider {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: black;
        background-position: 50%;
        background-size: cover;
        border-radius: 20px;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        .loader{
            opacity: 0.5;
        }

        &.swiper-lazy-loaded{
            .loader{
                opacity: 0;
            }
        }

        .slide-video{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            object-fit: cover;
            transition: all .5s ease-out;
        }
        
        &.launch-slider{
            flex-direction: column;

            .counter-slider {
                font-size: 15px;
                font-weight: 600;
                color: #FAEEC0;
            }

            .counter-slider {
                
                @media (min-width: 768px){
                    font-size: 25px;
                    margin-bottom: 20px;
                }

                @media (min-width: 1024px){
                    font-size: 40px;
                }

                .countdown-finished{
                    
                    @media (min-width: 0px) and (max-width: 1199px){
                        font-size: 1.5rem;
                        text-align: center;
                        margin-bottom: 0.5rem;
                    }
                    @media (min-width: 1200px){
                        font-size: 2rem;
                        text-align: center;
                        max-width: 920px;
                        margin-top: 2.5rem;
                    }
                }

            }
            

            

            .launch-desc {
                text-transform: uppercase;
                font-size: 10px;
                letter-spacing: 2px;
                margin-bottom: 10px;
                font-weight: 600;
                color: #FAEEC0;

                @media (min-width: 768px){
                    font-size: 30px;
                    letter-spacing: 8px;
                }

                @media (min-width: 1024px){
                    font-size: 40px;
                }

            }


            .launch-date{
                font-size: 20px;
                color: #FAEEC0;
                font-weight: 600;

                @media (min-width: 768px){
                    font-size: 40px;
                }
            }

            
        }

    }
    
    .swiper-pagination {
        position: absolute;
        display: none;
        align-items: center;
        justify-content: center;
        bottom: 50px !important;
        z-index: 500;
        left: 0;
        right: 0;
        
        @media(min-width: 768px) {
            display: flex;
        }
    
        .swiper-pagination-bullet {
            cursor: pointer;
            height: 10px;
            width: 10px;
            transition: all .2s ease-out;
            margin: 0 8px !important;
            opacity: 0.5;
            background-color: rgb(0, 0, 0);
            border-radius: 100%;
        }
    
        .swiper-pagination-bullet-active {
            height: 12px;
            width: 12px;
            opacity: 1;
            background-color: #ffffff;
        }
    }

    .swiper-button-prev {
        display: none;
        left: 40px !important;
        color: white !important;
        font-weight: bold !important;
        
        @media(min-width: 768px) {
            display: flex;
        }
        
        &::after {
            font-size: 55px !important;
        }
    }
    
    .swiper-button-next {
        display: none;
        right: 40px !important;
        color: white !important;
        font-weight: bold !important;
        
        @media(min-width: 768px) {
            display: flex;
        }

        &::after {
            font-size: 55px !important;
        }
    }
}
