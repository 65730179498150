.terms-sidebar {
    margin-top: 25px;
    background-color: #000000;
    padding: 0;
    display: none;
    position: sticky;
    top: 120px;
    @media(min-width: 992px) {
        display: block;
    }

    ul {
        margin: 0;
        padding: 0;
        .active {
            background-color: #131313;
            border-left: 4px solid #2AA9D8;
        }
        li {
            list-style: none;
            background-color: transparent;
            transition: .3s all ease-in-out;
            border-left: 4px solid #243042;
            
            &:hover {
                background-color: #131313;
                border-left: 4px solid #2AA9D8;
            }
            
            a {
                padding: 15px 25px;
                text-decoration: none;
                color: #ffffff;
                display: flex;
                align-items: center;
                
                img {
                    margin-right: 20px;
                    width: 22px;
                }
            }
        }
    }
}